<script>
import {mask} from 'vue-the-mask'
import axios from 'axios'
import LanguageSwitcher from "@/components/LanguageSwitcher.vue"
import { useI18n } from 'vue-i18n'
import Tr from "@/i18n/translation"

export default {
  components: { LanguageSwitcher },
  name: "PhoneNumber",
  directives: {mask},
  props: ['value', 'mymsq'],
  setup() {
    const { t } = useI18n()
    return { t }
  },
  beforeMount() {
    localStorage.clear()
  },
  methods: {
    handleInput(event) {
      let value = event.target.value;
      this.emitValidate(value);
    },
    async getAnswer(value) {

      const dataSent = {
        jsonrpc: '2.0',
        method: 'BookingSystemAPI_checkPhoneNumber',
        params: {'phoneNumber': value},
        id: 4228
      }
      this.blockEmit = true;
      const { data } = await axios.post(this.apiDomain, JSON.stringify(dataSent)).catch(error => {
        return error;
      });

      if(data == undefined)
      {
        this.msq = this.t("global.connectionError");
        //this.msq = 'Connection failed. Please try again later or contact with as by phone : 45 45 45 02';
        this.blockEmit = false;
      }
      else
      {
        var result = JSON.parse(data.result);
        if(result.customerExist)
        {
          this.blockEmit = false;
          this.msq = this.t("global.wait");
          //this.msq = 'You will be redirect to enter sms code view';
          localStorage.bookingId = result.bookingId;
          localStorage.phoneNumber = value;
          this.$router.push(Tr.i18nRoute({ name: 'Sms' }));
        }
        else
        {
          this.blockEmit = false;
          this.msq = this.t("sms.phoneNumberNotExist");
          //this.msq = 'Phone number not exist in our database.';
        }
      }

    },
    emitValidate(value){

      if(value.length == 10)
      {
          this.msq = this.t("global.wait");
          //this.msq = 'Search phone in our database. Please wait.';
          if(!this.blockEmit)
            this.getAnswer(value);
      }
      else
        this.msq = this.t('phone.phoneLabel');
        //this.msq = 'Phone number is too short';

    },

  },
  data() {
    return {
      text: '',
      msq: '',
      blockEmit: false,
    };
  },

}
</script>

<style scoped>
input{
  text-align: right;
}
</style>

<template>
  <div class="progress">
    <div class="progress-bar" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
    <div id="steps">{{ t("global.step")}} 1/5</div>
  </div>
  <form data-bitwarden-watching="1">
    <div class="row">
      <div class="col-md-12 switcher"  >
        <h5>{{ t("global.changeLang") }}</h5>
        <LanguageSwitcher ></LanguageSwitcher>
      </div>
    </div>
    <div style="margin-bottom: 30px;" >
      {{ t("phone.welcome") }} <span class="fw-bold"><span class="hcolor fw-bold">Hancom</span> {{ t("phone.booking") }} </span> <br/> {{ t("phone.information") }}
    </div>
    <div class="input-group mb-3">
      <span class="input-group-text" id="basic-addon2"> <i class="bi bi-telephone-forward-fill"></i> </span>
      <input type="text" ref="tel" v-mask="'###-##-###'" autofocus masked="true"  v-model="text" @input="handleInput" placeholder="_ _ _-_ _-_ _ _" class="form-control" aria-label="Username" aria-describedby="basic-addon2">
    </div>
    <div class="form-group mb-3">
      <small class="text-muted"> </small>
      <div class="invalid-feedback" style="display:block;" >
        {{ msq }}
      </div>
    </div>
  </form>

</template>