import {createRouter, createWebHistory, RouterView } from 'vue-router'
import Tr from "@/i18n/translation"

import PhoneNumber from './components/PhoneNumber.vue'
import SmsCode from './components/SmsCode.vue'
import MyBooking from './components/MyBooking.vue'
import MyAddress from './components/MyAddress.vue'
import MyConfirmation from './components/MyConfirmation.vue'
import MyInformation from './components/MyInformation.vue'

export const router = createRouter({
    history:createWebHistory(),
    routes:[
        {
            path: "/:locale?",
            component: RouterView,
            children:[
                { path: '', name:'Home', beforeEnter: Tr.routeMiddleware, component: PhoneNumber },
                { path: 'sms', name: 'Sms', beforeEnter: Tr.routeMiddleware, component: SmsCode },
                { path: 'booking', name: 'Booking', beforeEnter: Tr.routeMiddleware, component: MyBooking },
                { path: 'address', name: 'Address', beforeEnter: Tr.routeMiddleware, component: MyAddress },
                { path: 'confirmation', name: 'Confirmation', beforeEnter: Tr.routeMiddleware, component: MyConfirmation },
                { path: 'information', name: 'Information', beforeEnter: Tr.routeMiddleware, component: MyInformation },
            ]
        },
    ]
});