import { createI18n } from "vue-i18n";
import en from "./locales/en.json" // <--- add this
import no from "./locales/no.json" // <--- add this

export default createI18n({
    locale: process.env.VUA_APP_DEFAULT_LOCALE,
    fallbackLocale: process.env.VUA_APP_FALLBACK_LOCALE,
    legacy: false,
    globalInjection: true,
    messages: {no, en }
})