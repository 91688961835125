<script>
import Datepicker from 'vuejs3-datepicker';
import {mask} from 'vue-the-mask'
import axios from "axios";

import LanguageSwitcher from "@/components/LanguageSwitcher.vue";
import { useI18n } from 'vue-i18n'
import Tr from "@/i18n/translation"


axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded; application/json; charset=utf-8';

export default {
  components: {
    Datepicker, LanguageSwitcher
  },
  directives: {mask},
  setup() {
    const { t } = useI18n()
    return { t , Tr }
  },
  beforeMount() {
    if(localStorage.smsCode != '' && localStorage.bookingId > 0)
    {
      let today = new Date();
      this.getAvailableDates(today.getMonth(), today.getFullYear());
      this.getBookingInfo();
    }
    else
      this.$router.push(Tr.i18nRoute({ name: 'Home' }));

  },
  methods:{
    choseTimeSlot(info)
    {
      this.timeSlot = info.target.value;
    },
    backTo()
    {
      this.$router.push(Tr.i18nRoute({ name: 'Address' }));
    },
    selectedMethod(info){
      this.dateChosen= true ;
      this.date = info.toISOString().split('T')[0];
      this.getAvailableTimes(info.getFullYear()+'-'+info.getMonth()+'-'+info.getDay());
    },
    handleChangedMonth(info){
      let month = info.getMonth();
      let year = info.getFullYear();

      this.getAvailableDates(month, year);
    },
    async getBookingInfo()
    {
      const dataSent = {
        jsonrpc: '2.0',
        method: 'BookingSystemAPI_getBookingInfo',
        params: { 'smsCode': localStorage.smsCode, 'bookingId': localStorage.bookingId },
        id: 4228
      }
      const {data} = await axios.post(this.apiDomain, JSON.stringify(dataSent));
      var result = JSON.parse(data.result);

      if(result.error == 0 && result.bookingExist)
      {
        this.comment = result.booking.comments;
        this.contactPhone = result.booking.contactPhoneNumber;
      }
    },
    async getAvailableDates(month, year) {
      const dataSent = {
        jsonrpc: '2.0',
        method: 'BookingSystemAPI_getNotAvailableDate',
        params: {'smsCode': localStorage.smsCode, 'bookingId': localStorage.bookingId, month: month, year: year},
        id: 4228
      }
      const {data} = await axios.post(this.apiDomain, JSON.stringify(dataSent));
      var result = JSON.parse(data.result);
      //console.log(result);
      this.state.disabledDates.from = new Date(result.fromYear, result.fromMonth, result.fromDay);
      this.state.disabledDates.to = new Date(result.toYear, result.toMonth, result.toDay);
      for (var i = 0; i < result.notAvailableDate.length; i++)
      {
        var dateInfo = result.notAvailableDate[i].split('-');
        this.state.disabledDates.dates.push(new Date(dateInfo[0], dateInfo[1], dateInfo[2]));
      }
    },
    async getAvailableTimes() {
      const dataSent = {
        jsonrpc: '2.0',
        method: 'BookingSystemAPI_getAvailableHours',
        params: {'smsCode': localStorage.smsCode, 'bookingId': localStorage.bookingId,
          date: this.date,
        },
        id: 4228
      }
      const {data} = await axios.post(this.apiDomain, JSON.stringify(dataSent));
      var result = JSON.parse(data.result);
      this.items = result.timeSlot;
    },
    async confirmBooking()
    {
      //this.msg = 'Saving booking information. Please wait...';
      this.msg = this.t("global.wait");
      document.getElementById('errorForm').style.display = "none";
      const dataSent = {
        jsonrpc: '2.0',
        method: 'BookingSystemAPI_saveBooking',
        params: {'smsCode': localStorage.smsCode, 'bookingId': localStorage.bookingId,
          information:{
            date: this.date,
            timeSlot: this.timeSlot,
            comment: this.comment,
            contactPhoneNumber: this.contactPhone,
            confirmation: this.confirmation
          }
        },
        id: 4228
      }
      const {data} = await axios.post(this.apiDomain, JSON.stringify(dataSent));
      var result = JSON.parse(data.result);

      if(result.error == 0)
      {
        this.msg = '';
        this.$router.push(Tr.i18nRoute({ name: 'Confirmation' }));
      }
      else
      {
        this.msg = '';
        this.errorMsg = result.errorMsg;
        document.getElementById('errorForm').style.display = "block";
      }
    },
    validateForm()
    {
      var error = 0;
      if(this.timeSlot == '')
      {
        document.getElementById('timeSlotError').style.display = "block";
        let timeslots = document.getElementsByClassName('timeslotLabel');
        if(timeslots.length)
        {
          for(let i=0; i< timeslots.length; i++ )
          {
            timeslots[i].classList.remove('btn-outline-success');
            timeslots[i].classList.add('btn-outline-danger');
          }
        }
        error = 1;
      }
      else
      {
        document.getElementById('timeSlotError').style.display = "none";
        let timeslots = document.getElementsByClassName('timeslotLabel');
        if(timeslots.length)
        {
          for(let i=0; i< timeslots.length; i++ )
          {
            timeslots[i].classList.add('btn-outline-success');
            timeslots[i].classList.remove('btn-outline-danger');
          }
        }
      }

      if(!this.confirmation)
      {
        error = 1;
        document.getElementById('confirmError').style.display = "block";
        document.getElementById('confirmText').style.color = 'red';
      }
      else
      {
        document.getElementById('confirmError').style.display = "none";
        document.getElementById('confirmText').style.color = 'black';
      }

      return error;
    },
    submit(){
      if(this.validateForm() == 0)
        this.confirmBooking();
    }
  },
  data(){
    return {
      msg: '',
      errorMsg: '',
      timeSlot : '',
      comment: '',
      date: '',
      confirmation: false,
      contactPhone: '',
      items : [{name: 'loading...', quantity:1},],
      dateChosen: false,
      state: {
        disabledDates: {
          to: '',
          from: '',
          dates: [
            new Date(2023, 9, 10),
          ],
          preventDisableDateSelection: true
        }
      }
    }
  }

}
</script>

<style scoped>
.btn-outline-success{
  color:#40b983;
  border-color:#40b983;
}
.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show{
  border-color:#40b983;
  background-color: #40b983;
}
.timepick{
  width: 100%;
  margin-top: 20px;
}
.timepick div, fullWidth{
  width: 100%;
  display: block;
}
.btn-primary{
  width: 100%;
 }
.vuejs3-datepicker{
  text-align: left;
}

</style>

<template>
  <div class="progress"  >
    <div class="progress-bar" role="progressbar" style="width: 75%" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
    <div id="steps">{{ t("global.step")}} 4/5</div>
    <div class="langSwitcher">
      {{ t("global.changeLang") }}
      <LanguageSwitcher></LanguageSwitcher>
    </div>
  </div>
  <h5 class="form-label">{{t("booking.dateLabel")}}</h5>
  <datepicker @selected="selectedMethod" @changedMonth="handleChangedMonth" inline="true" :disabled-dates="state.disabledDates"
     :prevent-disable-date-selection="preventDisableDateSelection"
  ></datepicker>
  <div class="mb-3 mt-3" ref="timepick" v-if="dateChosen">
    <label class="form-label">{{t("booking.timeslotLabel")}}</label>
  <div class="btn-group-vertical timepick" role="group" aria-label="Vertical radio toggle button group">
    <div v-for="(item, index) in items" :key="index">
      <input type="radio" class="btn-check" name="vbtn-radio" :value="item.name" :id="item.name" autocomplete="off"   @click="choseTimeSlot">
      <label class="btn btn-outline-success fullWidth timeslotLabel" :for="item.name">{{ item.name }}</label>
    </div>
    <div class="invalid-feedback" id="timeSlotError" style="display: none;">{{ t("booking.timeslotRequired") }}</div>
  </div>

  </div>
  <div class="mb-3" v-if="dateChosen">
    <label for="exampleFormControlTextarea1" class="form-label">{{ t("booking.commentLabel") }}</label>
    <textarea class="form-control" id="exampleFormControlTextarea1" v-model="comment" rows="3"></textarea>
  </div>
  <div class="mb-3" v-if="dateChosen">
    <label for="contactPhone" class="form-label">{{ t("booking.contactPhoneLabel") }}</label>
    <input type="text" class="form-control" id="contactPhone"  v-model="contactPhone" style="text-align: right;" v-mask="'###-##-###'" masked="true" placeholder="_ _ _-_ _-_ _ _" >
    <small class="text-muted">{{ t("booking.contactPhoneInformation")}} </small>
  </div>
  <div class="mb-3">
    <div class="form-check">
      <input class="form-check-input" required type="checkbox" value="" v-model="confirmation" id="flexCheckIndeterminate">
      <label class="form-check-label" for="flexCheckIndeterminate">
        <small id="confirmText" >{{t("booking.confirmText")}}</small>
      </label>
      <div class="invalid-feedback" id="confirmError" style="display: none;">{{t("booking.acceptTerms")}}</div>
    </div>
  </div>
  <div class="mp-3" v-if="dateChosen">
    <div class="alert alert-danger" style="display: none;" id="errorForm" >{{errorMsg}}</div>
    <button type="button" @click="submit" class="btn btn-primary">{{t("global.confirm")}}</button>
    <div class="invalid-feedback" style="display:block;" >
      {{ msg }}
    </div>
  </div>
  <div class="mp-3" >
    <a style="cursor: pointer;" class="small linkColor" @click="backTo">{{t("booking.backToAddress")}}</a> | <router-link :to="Tr.i18nRoute({ name: 'Home' })" class="linkColor small">{{t("global.finishBooking")}}</router-link>
  </div>

</template>