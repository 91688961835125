<script >
import { useI18n } from 'vue-i18n'
import { useRouter } from "vue-router"
import Tr from "@/i18n/translation"
export default {
  setup() {
    const { t, locale } = useI18n()
    const supportedLocales = Tr.supportedLocales
    const flags = Tr.supportedLocalesFlag;
    const router = useRouter()
    const switchLanguage = async (event) => {
      const newLocale = event.target.value
      await Tr.switchLanguage(newLocale)

      try {
        await router.replace({ params: { locale: newLocale } })
      } catch(e) {
        router.push("/")
      }

    }
    const switchLanguageClick = async (event) => {
      console.log(event.target.attributes.value.value);
      const newLocale = event.target.attributes.value.value
      await Tr.switchLanguage(newLocale)

      try {
        await router.replace({ params: { locale: newLocale } })
      } catch(e) {
        router.push("/")
      }
    }
    return { t, locale, supportedLocales, switchLanguage, flags, switchLanguageClick }
  },
  methods:{
    getImgUrl: function (path) {
      return require('@/assets/' + path + '.png');
    }
  }
}
</script>

<template>


  <img v-for="flag in supportedLocales" class="flag selectLangFlag"  :src="getImgUrl(flag)" height="20" :value="flag" :key="flag"  @click="switchLanguageClick"  />
  <select @change="switchLanguage" class="selectLang">
    <option
        v-for="sLocale in supportedLocales"
        :key="`locale-${sLocale}`"
        :value="sLocale"
        :selected="locale === sLocale"
    >
    {{ t(`locale.${sLocale}`) }}
    </option>
  </select>
</template>

<style scoped>
.flag{
  cursor: pointer;
  margin: 8px;
  border: 2px solid #4ab948;
}
.noDisplay{
  display: none;
}
</style>