import i18n from "@/i18n" // <--- 1

const Trans = {
    get currentLocale() {
        return i18n.global.locale.value
    },
    i18nRoute(to) {
        return {
            ...to,
            params: {
                locale: Trans.currentLocale,
                ...to.params
            }
        }
    },
    async routeMiddleware(to, _from, next) {
        const paramLocale = to.params.locale
        if(!Trans.isLocaleSupported(paramLocale)) {
            return next(Trans.guessDefaultLocale())
        }
        await Trans.switchLanguage(paramLocale)
        return next()
    },
    guessDefaultLocale() {
        const userPersistedLocale = Trans.getPersistedLocale()
        if(userPersistedLocale) {
            return userPersistedLocale
        }
        const userPreferredLocale = Trans.getUserLocale()
        if (Trans.isLocaleSupported(userPreferredLocale.locale)) {
            return userPreferredLocale.locale
        }
        if (Trans.isLocaleSupported(userPreferredLocale.localeNoRegion)) {
            return userPreferredLocale.localeNoRegion
        }

        return Trans.defaultLocale
    },
    get defaultLocale() {
        return process.env.VUE_APP_DEFAULT_LOCALE
    },
    isLocaleSupported(locale) {
        return Trans.supportedLocales.includes(locale)
    },
    getUserLocale() {
        const locale = window.navigator.language ||
            window.navigator.userLanguage ||
            Trans.defaultLocale
        return {
            locale: locale,
            localeNoRegion: locale.split('-')[0]
        }
    },
    getPersistedLocale() {
        const persistedLocale = localStorage.getItem("user-locale")
        if(Trans.isLocaleSupported(persistedLocale)) {
            return persistedLocale
        } else {
            return null
        }
    },
    get supportedLocales() {
        return process.env.VUE_APP_SUPPORTED_LOCALES.split(",");
    },
    get supportedLocalesFlag() {
        const lang = this.supportedLocales;
        let imageFlagTab = [];
        lang.forEach((value) => {
            imageFlagTab.push(value+'.png');
        });

        return imageFlagTab;
    },
    set currentLocale(newLocale) { // <--- 2
        i18n.global.locale.value = newLocale
    },
    async switchLanguage(newLocale) {
        Trans.currentLocale = newLocale
        document.querySelector("html").setAttribute("lang", newLocale)
        localStorage.setItem("user-locale", newLocale)
    },
}
export default Trans