<script>
export default {
  name: 'App',
}
</script>

<style>
.red{
  color: red;
}

header{
  margin-bottom: 40px;
}

</style>

<template>
  <div  class="card border-success shadow rounded myP40"  >
    <main class="form-signin">
      <header>
        <img alt="Hancom logo" class="logo"  src="./assets/logo_hancom.png">
        <div class="float-end small badge badge-hancom" >Kundebooking</div>
      </header>

      <router-view></router-view>
    </main>
    <footer>
      <div class="container">

        <footer class="py-3 my-4">
          <ul class="nav justify-content-center border-bottom pb-3 mb-3" style="font-size: 13px;">
            <!--
            <li class="nav-item"><router-link to="/"> Phone view | </router-link></li>
            <li class="nav-item"><router-link to="/sms"> Sms view | </router-link></li>
            <li class="nav-item"><router-link to="/address"> Address confirmation | </router-link></li>
            <li class="nav-item"><router-link to="/booking"> Booking system view | </router-link></li>
            <li class="nav-item"><router-link to="/confirmation"> Confirmation view | </router-link></li>
            <li class="nav-item"><router-link to="/information"> Information view</router-link></li>
            -->
          </ul>
          <p class="text-center text-muted" style="font-size: 12px;">
            <span class="fw-bolder"> Adresse: </span> Professor Birkelandsvei 36 1081 Oslo

            <span class="fw-bolder">Telefon:</span> 45 45 45 02

            <span class="fw-bolder">E-post:</span> <a href="mailto:kontakt@hancom.no" style="color: #181818;">kontakt@hancom.no</a> <br/>
            © 2024
            <a href="https://www.hancom.no" target="_blank">www.hancom.no</a> </p>
        </footer>
      </div>
    </footer>
  </div>
</template>
