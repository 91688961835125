<template>
  <div class="progress">
    <div class="progress-bar" role="progressbar" style="width: 0%" aria-valuenow="0" aria-valuemin="0" aria-valuemax="0"></div>
  </div>
  <div >
    <span class="fw-bold ">404 Page </span> <br/>
    Please <router-link to="/" class="linkColor"> try again</router-link> or contact the <span class="hcolor">Hancom</span> office by phone: <br/> <span class="fw-bold"> 45 45 45 02</span>
  </div>
</template>

<script>
export default {
  name: "MyInformation"
}
</script>

<style scoped>

</style>